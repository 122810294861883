<template>
    <div>
        <b-row>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas</span>
                    </CCardHeader>
                    <CCardBody>
                        <span class="h6 text-muted">ESTADÍSTICAS DE LAS QUEJAS DE USUARIOS
                        </span>
                        <hr>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="12" class=" my-2 icon-custom">
                                <!-- <i style="inline-size: auto" class="ico fas fa-check-double fa-3x pb-1"></i> -->
                                <i style="inline-size: auto" class="ico far fa-angry fa-3x pb-1"></i>
                                <br>
                                <span class="lg-numero">{{listQuejasUsuario.length}}</span>
                                <br>
                                <span class="text-muted">Quejas Registradas</span>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="12">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-list fa-md"></i><span class="h5"> Lista de Quejas de Usuarios</span>
                        <b-button v-if="listQuejasUsuario.length >= 1" class="float-right" variant="success" size="sm" @click="exportDataToExcel">
                        <i class="fas fa-download mr-1"></i>
                        Exportar</b-button>
                    </CCardHeader>
                    <CCardBody>
                        <b-row>
                            <b-col md="3">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table bordered hover show-empty mediun responsive outlined :items="listQuejasUsuario" :fields="camposQuejasUsuario" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template v-slot:cell(nroDoc)="data">
                                        {{data.item.numeroDoc | truncate(30)}}
                                    </template>
                                    <template v-slot:cell(nombre)="data">
                                        {{data.item.nombrePasajero | truncate(30)}}
                                    </template>
                                    <template v-slot:cell(queja)="data">
                                        {{data.item.tipoQueja == 1 ? 'Queja o Reclamo' : data.item.tipoQueja == 2 ? 'Sugerencia' : data.item.tipoQueja == 3 ? 'Observación' : '' | truncate(30) }}
                                    </template>
                                    <template v-slot:cell(guiaPrincipal)="data">
                                        <!-- {{listUsuarios.find(x => x.idGuia == data.item.idGuia).nombre | truncate(50)}} -->
                                        {{data.item.nombreGuia | truncate(50)}}
                                    </template>
                                    <template v-slot:cell(caminata)="data">
                                        {{data.item.caminata | truncate(30)}}
                                    </template>
                                    <!-- <template v-slot:cell(argumento)="data">
                                        {{data.item.argumento | truncate(30)}}
                                    </template> -->
                                    <template v-slot:cell(fechaHora)="data">
                                        {{formatearFecha(data.item.fechaFormat,'DD-MM-YYYY') | truncate(30)}} - {{data.item.horaFormat | truncate(30)}}
                                    </template>
                                    <template v-slot:cell(opciones)="param">
                                        <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrilModalQuejaUsuario(param)" variant="dark" class=" mr-1 mb-1">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </span>
                                        </b-button>

                                        <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarQuejaUsuario(param)" variant="danger">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </CCardBody>
                    <CCardFooter>
                        <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                    </CCardfooter>
                </CCard>
            </b-col>
        </b-row>

        <CModal :show.sync="modalActQuejaUsuario" size="xl" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title">
                    <i class="fas fa-plus-square fa-md"></i>
                    <span class="h5"> Mostrar Quejas Usuario</span>
                </h6>
                <CButtonClose @click="modalActQuejaUsuario = false" class="text-white" />
            </template>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit()">
                    <b-row>
                        <b-col md="6">
                            <validation-provider name="numero de documento" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Número de Documento:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Número de Documento" v-model.trim="datosActQuejasUsuario.numeroDoc"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="Nombre del Pasajero" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nombre del Pasajero:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el nombre del Pasajero" v-model.trim="datosActQuejasUsuario.nombrePasajero"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Fecha:" class="mb-2">
                                    <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosActQuejasUsuario.fechaFormat"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="hora" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Hora:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese la Hora" v-model.trim="datosActQuejasUsuario.horaFormat"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="tipo queja" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Tipo de Queja:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el tipo de queja" v-model.trim="datosActQuejasUsuario.tipoQueja"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="nombre del guia" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nombre del Guia:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Nombre del Guia" v-model.trim="datosActQuejasUsuario.nombreGuia"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="caminata" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Caminata:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese la Caminata" v-model.trim="datosActQuejasUsuario.caminata"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="argumento" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Argumento:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Argumento" v-model.trim="datosActQuejasUsuario.argumento"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </CModal>
    </div>
</template>

<script>
import firebase from 'firebase';
import XLSX from 'sheetjs-style-v2';
const dbUsuario = firebase.firestore().collection('users');
const dbQuejasUsuario = firebase.firestore().collection('quejasReclamos');

export default {
    data() {
        return {

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            filter: null,
            filterOn: [],

            listQuejasUsuario : [],
            listUsuarios: [],

            modalActQuejaUsuario: false,

            datosActQuejasUsuario: {
                id: '',
                numeroDoc: '',
                nombrePasajero: '',
                tipoQueja: '',
                idGuia: '',
                horaFormat: '',
                fechaFormat: moment().format('YYYY-MM-DD'),
                caminata: '',
                argumento: '',
                nombreGuia: '',
            },

            camposQuejasUsuario: [
                {
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nroDoc",
                    label: "Nro. Documento",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "queja",
                    label: "Queja",
                    class: "text-center",
                },
                {
                    key: "guiaPrincipal",
                    label: "Guia Principal",
                    class: "text-center",
                },
                {
                    key: "caminata",
                    label: "Caminata",
                    class: "text-center",
                },
                // {
                //     key: "argumento",
                //     label: "Argumento",
                //     class: "text-center",
                // },
                {
                    key: "fechaHora",
                    label: "Fecha y Hora",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],
        }
    },
    methods: {
        exportDataToExcel(){
            let now = moment().format('YYYYMMDDHHmm');

            const valUpper = text => {
                if (text != null && text !== undefined) {
                    return text.toString().toUpperCase();
                }
                return '';
            };

            const valDate = date => {
                if (date && date !== undefined) {
                    return moment(date).format('DD-MM-YYYY')
                }
                return '';
            };

            let dataExcel = this.listQuejasUsuario.map(data => {
                return {
                    A: valUpper(data['numeroDoc']),
                    B: valUpper(data['nombrePasajero']),
                    C: data['tipoQueja'] == 1 ? 'QUEJA O RECLAMO' : data['tipoQueja'] == 2 ? 'SUGERENCIA' : data['tipoQueja'] == 3 ? 'OBSERVACIÓN' : '',
                    D: valUpper(data['horaFormat']),
                    E: valDate(data['fechaFormat']),
                    F: valUpper(data['nombreGuia']),
                    G: valUpper(data['caminata']),
                    H: valUpper(data['argumento']),
                };
            });

            let dataWS = XLSX.utils.json_to_sheet(dataExcel, {
                origin: 'A4',
            });

            let dataWSMerges = [{
                s: {
                    r: 1,
                    c: 1,
                },
                e: {
                    r: 1,
                    c: 3,
                },
            }];

            dataWS['!merges'] = dataWSMerges;

            let dataWSCols = [
                {
                    wch: 30, //A
                },
                {
                    wch: 40, //B
                },
                {
                    wch: 15, //C
                },
                {
                    wch: 15, //D
                },
                {
                    wch: 15, //E
                },
                {
                    wch: 50, //F
                },
                {
                    wch: 30, //G
                },
                {
                    wch: 30, //H
                },
            ];

            dataWS['!cols'] = dataWSCols;

            for(let i of [
                {
                    f: 'B2',
                    v: 'QUEJAS DE USUARIO',
                },
                {
                    f: 'C2',
                },
                {
                    f: 'D2',
                },
                {
                    f: 'A4',
                    v: 'NUMERO DE DOCUMENTO',
                },
                {
                    f: 'B4',
                    v: 'NOMBRE DE PASAJERO',
                },
                {
                    f: 'C4',
                    v: 'TIPO DE QUEJA',
                },
                {
                    f: 'D4',
                    v: 'HORA',
                },
                {
                    f: 'E4',
                    v: 'FECHA',
                },
                {
                    f: 'F4',
                    v: 'NOMBRE DEL GUIA PRINCIPAL',
                },
                {
                    f: 'G4',
                    v: 'CAMINATA',
                },
                {
                    f: 'H4',
                    v: 'ARGUMENTO',
                },
            ]){
                XLSX.utils.sheet_add_aoa(dataWS, [
                    [i.v || '']
                ], {
                    origin: i.f,
                });

                dataWS[i.f].s = {
                    numFmt: i.w || 'general',
                    fill: {
                        fgColor: {
                            rgb: i.b ? 'FFFFFF' : 'F2F2F2',
                        },
                    },
                    font: {
                        name: 'arial',
                        sz: 8,
                        bold: i.b ? false : true,
                        color: {
                            rgb: i.b ? '000000' : '1F497D',
                        },
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center',
                        wrapText: 1,
                    },
                };
            }

            for (let k in dataExcel) {
                for (let i of Object.keys(dataExcel[0])) {
                    dataWS[`${i}${(parseInt(k) + 5).toString()}`].s = {
                        numFmt: '@',
                        fill: {
                            fgColor: {
                                rgb: 'FFFFFF',
                            },
                        },
                        font: {
                            name: 'arial',
                            sz: 8,
                            color: {
                                rgb: '000000',
                            },
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                        },
                        alignment: {
                            horizontal: 'center',
                            vertical: 'center',
                        },
                    };
                }
            }

            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS, 'QUEJAS DE USUARIOS');
            XLSX.writeFile(wb, `QUEJAS DE USUARIOS ${now}.xlsx`);
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length;
            this.paginaActual = 1;
        },
        formatearFecha(fecha,formato){
            return moment(fecha).format(formato);
        },
        listarUsuarios() {
            let me = this;

            dbUsuario
            .where("estado" , "==" , 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .where("rol","==","guia")
            .onSnapshot((querySnapshot) => {
                me.listUsuarios = [];
                querySnapshot.forEach((doc) => {
                    me.listUsuarios.push({
                        idGuia: doc.id,
                        nombre: doc.data().nombre + ' ' + doc.data().apellidoPaterno + ' ' + doc.data().apellidoMaterno,
                    });
                });
            });
        },
        listarQuejasUsuario() {
            let me = this;

            dbQuejasUsuario
            .where("estado","==", 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .onSnapshot((querySnapshot) => {
                me.listQuejasUsuario = [];
                querySnapshot.forEach((doc)=>{
                    me.listQuejasUsuario.push({
                        id: doc.id,
                        argumento: doc.data().argumento,
                        caminata: doc.data().caminata,
                        fechaCreacion: doc.data().fechaCreacion,
                        fechaFormat: doc.data().fechaFormat,
                        horaFormat: doc.data().horaFormat,
                        idGuia: doc.data().idGuia,
                        nombrePasajero: doc.data().nombrePasajero,
                        numeroDoc: doc.data().numeroDoc,
                        tipoQueja: doc.data().tipoQueja,
                        nombreGuia: me.listUsuarios.find(x => x.idGuia == doc.data().idGuia) == null ? '' : me.listUsuarios.find(x => x.idGuia == doc.data().idGuia).nombre,
                    });
                });
                
                me.listQuejasUsuario.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
            });
        },
        eliminarQuejaUsuario(param) {
            let me = this;

            me.$swal.fire({
                title: '¿Estas seguro de eliminar la Queja?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbQuejasUsuario.doc(param.item.id).update({
                        estado: 1,
                        fechaEliminar: firebase.firestore.Timestamp.fromDate(new Date()),
                    })
                    .then(() => {
                        me.swat('success', 'Eliminado satisfactoriamente')
                        // me.disabled = false;
                    })
                    .catch((error) => {
                        // me.disabled = false;
                        me.swat('error', 'Algo salió mal!')
                    });

                }
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        abrilModalQuejaUsuario(param){
            let me = this;
            
            me.datosActQuejasUsuario.id = param.item.id;
            me.datosActQuejasUsuario.numeroDoc = param.item.numeroDoc;
            me.datosActQuejasUsuario.nombrePasajero = param.item.nombrePasajero;

            if (param.item.tipoQueja == 1) {
                me.datosActQuejasUsuario.tipoQueja = 'Queja o Reclamo';
            }else if (param.item.tipoQueja == 2) {
                me.datosActQuejasUsuario.tipoQueja = 'Sugerencia';
            }else if (param.item.tipoQueja == 3) {
                me.datosActQuejasUsuario.tipoQueja = 'Observación';
            }else {
                me.datosActQuejasUsuario.tipoQueja = '';
            }
            
            me.datosActQuejasUsuario.idGuia = param.item.idGuia;
            me.datosActQuejasUsuario.horaFormat = param.item.horaFormat;
            me.datosActQuejasUsuario.fechaFormat = param.item.fechaFormat;
            me.datosActQuejasUsuario.caminata = param.item.caminata;
            me.datosActQuejasUsuario.argumento = param.item.argumento;
            me.datosActQuejasUsuario.nombreGuia = param.item.nombreGuia;

            me.modalActQuejaUsuario = true;
        },
        resetModalQuejaUsuario(){
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });

            this.datosActQuejasUsuario.id = '';
            this.datosActQuejasUsuario.numeroDoc = '';
            this.datosActQuejasUsuario.nombrePasajero = '';
            this.datosActQuejasUsuario.tipoQueja = '';
            this.datosActQuejasUsuario.idGuia = '';
            this.datosActQuejasUsuario.horaFormat = '';
            this.datosActQuejasUsuario.fechaFormat = moment().format('YYYY-MM-DD');
            this.datosActQuejasUsuario.caminata = '';
            this.datosActQuejasUsuario.argumento = '';
            this.datosActQuejasUsuario.nombreGuia = '';
        },
    },
    computed: {

    },
    watch: {
        modalActQuejaUsuario: function (val) {
            if (val == false) {
                this.resetModalQuejaUsuario();
            }
        },
    },
    mounted(){
        if (this.$store.state.user) {
            this.listarUsuarios();
            this.listarQuejasUsuario();
        }
    }
}
</script>